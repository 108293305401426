import { MoreHorizontal } from 'lucide-react';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConfirmDeleteDialog from '@/components/layout/ConfirmDeleteDialog.tsx';
import SelectUserParcelDialog from '@/components/layout/users/SelectUserParcelDialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Checkbox } from '@/components/ui/checkbox.tsx';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx';
import { TableCell, TableRow } from '@/components/ui/table.tsx';
import { config } from '@/config/config.ts';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { getParcelsForUser } from '@/redux/users/users.actions.ts';
import {
  FetchUser,
  GetParcelsForUserResponse,
} from '@/redux/users/users.types.ts';
import { formatCreationDate, formatPaymentDate } from '@/utils/dateUtil.ts';

type UserTableRowProps = {
  user: FetchUser;
  onDeleteUser: (userId: string) => void;
  showRemoveFromDistributor?: boolean;
  onRemoveUserClick?: (userId: string) => void;
};

const UserTableRow: FC<UserTableRowProps> = ({
  user,
  onDeleteUser,
  showRemoveFromDistributor,
  onRemoveUserClick,
}) => {
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const [parcelDialogOpened, setParcelDialogOpened] = useState(false);
  const [deleteDialogOpened, setIsDeleteDialogOpened] =
    useState<boolean>(false);

  const handleErrorResponse = (response: GetParcelsForUserResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };
  const onUserEmailClick = async (
    e: React.MouseEvent<HTMLTableCellElement>,
    userEmail: string
  ) => {
    e.stopPropagation();
    window.open(
      `${config.userAppUrl}?email=${userEmail}&token=${localStorage.getItem('token')}&isAdmin=true`,
      '_blank'
    );
  };

  const onParcelColumnClick = async (
    e: React.MouseEvent<HTMLTableCellElement>,
    userId: string
  ) => {
    e.stopPropagation();
    // @ts-ignore
    const response = await dispatch(getParcelsForUser(userId)).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    if (response.content.length === 0) {
      toast.info('Korisnik nema ni jednu parcelu');
      return;
    }

    if (response.content.length === 1) {
      router(`/parcels/edit/${response.content[0].id}`);
      return;
    }

    setParcelDialogOpened(true);
  };

  const onEditUserClick = (userId: string) => {
    router(`/users/edit/${userId}`);
  };

  const onDeleteClick = () => {
    setIsDeleteDialogOpened(true);
  };

  const onDeleteUserClick = async () => {
    onDeleteUser(user.id);
  };

  if (showRemoveFromDistributor && !onRemoveUserClick) {
    return null;
  }

  return (
    <>
      <TableRow>
        <TableCell
          className="max-sm:hidden cursor-pointer"
          onClick={() => onEditUserClick(user.id)}
        >
          {user.full_name}
        </TableCell>
        <TableCell className="max-sm:hidden">{user.place}</TableCell>
        <TableCell className="max-xl:hidden">{user.country}</TableCell>
        <TableCell
          className="text-[#007bff] hover:underline font-bold text-wrap flex-wrap break-words break-all whitespace-normal"
          onClick={(e) => onUserEmailClick(e, user.email)}
        >
          {user.email}
        </TableCell>
        <TableCell className="max-xl:hidden">{user.phoneNumber}</TableCell>
        <TableCell
          className="max-lg:hidden font-bold text-[#007bff] text-base cursor-pointer"
          onClick={(e) => onParcelColumnClick(e, user.id)}
        >
          {user.parcelCount}
        </TableCell>
        <TableCell className="max-xl:hidden">
          {formatCreationDate(new Date(user.createdAt))}
        </TableCell>
        <TableCell className="max-2xl:hidden">
          {formatPaymentDate(new Date(user.dateOfPayment))}
        </TableCell>
        <TableCell className="max-lg:hidden">
          <Checkbox
            checked={user.paidSubscription}
            contentEditable={false}
            className="data-[state=checked]:bg-[#2b90d9]"
          />
        </TableCell>
        <TableCell>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button aria-haspopup="true" size="icon" variant="ghost">
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {showRemoveFromDistributor ? (
                <DropdownMenuItem onClick={() => onRemoveUserClick(user.id)}>
                  Ukloni distributera za korisnika
                </DropdownMenuItem>
              ) : null}
              <DropdownMenuItem onClick={() => onEditUserClick(user.id)}>
                Izmeni korisnika
              </DropdownMenuItem>
              <DropdownMenuItem
                className="focus:text-red-500 cursor-pointer"
                onClick={onDeleteClick}
              >
                Obriši korisnika
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </TableCell>
      </TableRow>
      <ConfirmDeleteDialog
        headerText="Da li ste sigurni da želite da obrišete korisnika?"
        descriptionText="Ova akcija se ne može poništiti. Trajno ćete obrisati korisnika."
        onContinueClick={onDeleteUserClick}
        isDialogOpen={deleteDialogOpened}
        setIsDialogOpen={setIsDeleteDialogOpened}
      />
      <SelectUserParcelDialog
        isDialogOpen={parcelDialogOpened}
        setIsDialogOpen={setParcelDialogOpened}
      />
    </>
  );
};

export default UserTableRow;
