import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DeviceRevision,
  DevicesForUser,
  FERTDevice,
  FetchDevice,
  SensDevice,
  StartDevice,
} from '@/redux/device/devices.types.ts';

type DevicesInitialState = {
  availableZones: number[];
  devices: FetchDevice[];
  startDeviceForEdit: StartDevice;
  fertDeviceForEdit: FERTDevice;
  sensDeviceForEdit: SensDevice;
  userDevices: DevicesForUser;
  devicesRevisions: DeviceRevision[];
};

const devicesInitialState: DevicesInitialState = {
  availableZones: [],
  devices: [],
  startDeviceForEdit: null,
  fertDeviceForEdit: null,
  sensDeviceForEdit: null,
  userDevices: null,
  devicesRevisions: [],
};

const devicesSlice = createSlice({
  name: 'devicesSlice',
  initialState: devicesInitialState,
  reducers: {
    setAvailableZones: (state, action: PayloadAction<number[]>) => {
      state.availableZones = action.payload;
    },
    setDevices: (state, action: PayloadAction<FetchDevice[]>) => {
      state.devices = action.payload;
    },
    setStartDeviceForEdit: (state, action: PayloadAction<StartDevice>) => {
      state.startDeviceForEdit = action.payload;
    },
    setFERTDeviceForEdit: (state, action: PayloadAction<FERTDevice>) => {
      state.fertDeviceForEdit = action.payload;
    },
    setSensDeviceForEdit: (state, action: PayloadAction<SensDevice>) => {
      state.sensDeviceForEdit = action.payload;
    },
    removeDeletedDevice: (state, action: PayloadAction<string>) => {
      state.devices = state.devices.filter(
        (device) => device.id !== action.payload
      );
    },
    setUserDevices: (state, action: PayloadAction<DevicesForUser>) => {
      state.userDevices = action.payload;
    },
    setDevicesRevisions: (state, action: PayloadAction<DeviceRevision[]>) => {
      state.devicesRevisions = action.payload || [];
    },
    patchFERTDeliveryState: (state, action: PayloadAction<FERTDevice>) => {
      const indexToReplace = state.userDevices.fertDevices.findIndex(
        (device) => device.id === action.payload.id
      );

      if (indexToReplace !== -1) {
        state.userDevices.fertDevices[indexToReplace] = action.payload;
      }
    },
    patchSTARTDeliveryState: (state, action: PayloadAction<StartDevice>) => {
      const indexToReplace = state.userDevices.startDevices.findIndex(
        (device) => device.id === action.payload.id
      );

      if (indexToReplace !== -1) {
        state.userDevices.startDevices[indexToReplace] = action.payload;
      }
    },
    patchSENSDeliveryState: (state, action: PayloadAction<SensDevice>) => {
      const indexToReplace = state.userDevices.sensDevices.findIndex(
        (device) => device.id === action.payload.id
      );

      if (indexToReplace !== -1) {
        state.userDevices.sensDevices[indexToReplace] = action.payload;
      }
    },
  },
});

export const {
  setAvailableZones,
  setDevices,
  setStartDeviceForEdit,
  setFERTDeviceForEdit,
  setSensDeviceForEdit,
  removeDeletedDevice,
  setUserDevices,
  setDevicesRevisions,
  patchFERTDeliveryState,
  patchSTARTDeliveryState,
  patchSENSDeliveryState,
} = devicesSlice.actions;
export default devicesSlice.reducer;
