import { EditParcelFormData } from '@/components/forms/EditParcelForm.tsx';

export const createEditParcelStartData = (): EditParcelFormData => {
  return {
    id: '',
    userId: '',
    name: '',
    numberOfZones: '1',
    maxBetweenTwoShifts: '60',
    place: '',
    country: '',
    weatherForecast: 'Novi Sad',
    simCardNumber: '',
    boundaryForDryLand: '0',
    boundaryForWetlands: '0',
    humiditySensor: false,
    secondHumiditySensor: false,
    supplementMixer: false,
    supplement: false,
    voltage: false,
    rawSensorValues: true,
    coordinates: '',
    numberOfIrrigation: false,
    airHumidity: false,
    pumpSensor: false,
    numberOfZonesSwitch: false,
    airTemperature: false,
    soilTemperatureSensor: false,
    soilSalinity: false,
    flowMeter: false,
    waterLevelSensor: false,
    pHSensor: false,
    flowMeterCapacity: '0',
    flowMeterFactorK: '0',
    flowMeterFactorM: '0',
    pHFactorK: '0',
    minHumidityForAutomaticIrrigation: '0',
    optimalHumidityForAutomaticIrrigation: '0',
    durationOfTheActiveValve: '0',
    timeBetweenValves: '0',
    moreSENSSensors: false,
  };
};
