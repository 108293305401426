import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import AddNewIcon from '@/components/controls/AddNewIcon.tsx';
import DevicesContent from '@/components/layout/devices/DevicesContent.tsx';
import PageHeader from '@/components/layout/PageHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getAllDevicesForParcel } from '@/redux/device/devices.actions.ts';
import { GetDevicesResponse } from '@/redux/device/devices.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

const DevicesPage: FC = () => {
  const params = useParams();
  const router = useNavigate();
  const dispatch = useAppDispatch();

  const handleErrorResponse = (response: GetDevicesResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchDevices = async () => {
      // @ts-ignore
      const response = await dispatch(
        getAllDevicesForParcel(params.parcelId)
      ).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput={false} />
        <div className="grid items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <AddNewIcon
            link={`/devices/create/${params.parcelId}`}
            text="Dodaj uredjaj"
          />
          <DevicesContent />
        </div>
      </div>
    </div>
  );
};

export default DevicesPage;
