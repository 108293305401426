import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';

type UserInfoFormProps = {
  form: UseFormReturn<any>;
  isEdit?: boolean;
};

const UserInfoForm: FC<UserInfoFormProps> = ({ form, isEdit }) => {
  return (
    <>
      <CustomFormInput
        customForm={form}
        name="name"
        labelText="Ime"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="surname"
        labelText="Prezime"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="email"
        labelText="Email"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-sm:w-full md:w-[500px]"
        type="text"
      />
      {!isEdit ? (
        <CustomFormInput
          customForm={form}
          name="password"
          labelText="Lozinka"
          formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
          labelStyle="max-sm:w-full sm:w-[300px]"
          divStyle="max-sm:w-full md:w-[500px]"
          type="password"
        />
      ) : null}
      <CustomFormInput
        customForm={form}
        name="phoneNumber"
        labelText="Telefon"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-sm:w-full md:w-[500px]"
        type="text"
      />
    </>
  );
};

export default UserInfoForm;
