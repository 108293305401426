import { FC } from 'react';

import CustomTableHeader, {
  TableHeaderType,
} from '@/components/layout/CustomTableHeader.tsx';
import PaginationSection from '@/components/layout/PaginationSection.tsx';
import ParcelTableRow from '@/components/layout/parcels/ParcelTableRow.tsx';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { Table, TableBody } from '@/components/ui/table.tsx';
import { useAppSelector } from '@/redux/hooks.ts';
import {
  getFilteredNumberOfParcels,
  getParcels,
  getTotalNumberOfParcels,
} from '@/redux/parcels/parcels.selectors.ts';

const parcelsTableHeaders: TableHeaderType[] = [
  {
    id: 'user',
    text: 'Korisnik',
    className: 'max-lg:hidden',
  },
  {
    id: 'name',
    text: 'Parcela',
    className: '',
  },
  {
    id: 'place',
    text: 'Mesto',
    className: '',
  },
  { id: 'country', text: 'Država', className: 'max-md:hidden' },
  { id: 'lastSync', text: 'Sinhronizovan', className: 'max-md:hidden' },
  {
    id: 'actions',
    text: '',
    className: '',
    isSortDisabled: true,
  },
];

type ParcelsTableSectionProps = {
  page: number;
  rowsPerPage: number;
  onColumnClick: (column: string) => void;
  onPageChangeClick: (selectedPage: number) => void;
  onDeleteParcel: (parcelId: string) => void;
  onRowsPerPageChange: (selectedRowsPerPage: number) => void;
};

const ParcelsTableSection: FC<ParcelsTableSectionProps> = ({
  page,
  rowsPerPage,
  onColumnClick,
  onPageChangeClick,
  onDeleteParcel,
  onRowsPerPageChange,
}) => {
  const parcels = useAppSelector(getParcels);
  const totalNumberOfParcels = useAppSelector(getTotalNumberOfParcels);
  const filteredNumberOfParcels = useAppSelector(getFilteredNumberOfParcels);

  return (
    <Card x-chunk="dashboard-06-chunk-0">
      <CardHeader>
        <CardTitle>Parcele</CardTitle>
      </CardHeader>
      <CardContent className="max-sm:p-2">
        <Table>
          <CustomTableHeader
            tableName="parcels"
            tableHeaders={parcelsTableHeaders}
            onHeaderCellClick={onColumnClick}
          />
          <TableBody>
            {parcels.map((parcel, index) => (
              <ParcelTableRow
                key={`parcels-table-data-parcel-${index + 1}`}
                parcel={parcel}
                onParcelDelete={onDeleteParcel}
              />
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter>
        <PaginationSection
          page={page}
          rowsPerPage={rowsPerPage}
          filteredNumber={filteredNumberOfParcels}
          totalNumber={totalNumberOfParcels}
          onPageChangeClick={onPageChangeClick}
          entity="parcela"
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </CardFooter>
    </Card>
  );
};

export default ParcelsTableSection;
