import { zodResolver } from '@hookform/resolvers/zod';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import FERTDeviceFactorForm from '@/components/forms/devices/FERTDeviceFactorForm.tsx';
import FERTDeviceInfoForm from '@/components/forms/devices/FERTDeviceInfoForm.tsx';
import FERTDeviceSensorsForm from '@/components/forms/devices/FERTDeviceSensorsForm.tsx';
import FERTDeviceSupplementForm from '@/components/forms/devices/FERTDeviceSupplementForm.tsx';
import CreateButtonSection from '@/components/forms/shared/CreateButtonSection.tsx';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { createFERTDevice } from '@/redux/device/devices.actions.ts';
import { getAvailableZones } from '@/redux/device/devices.selectors.ts';
import { CreateDeviceResponse } from '@/redux/device/devices.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { createFERTDeviceSchema } from '@/zod/device.types.zod.ts';

export type CreateFERTDeviceFormData = {
  parcelId: string;
  name: string;
  color: string;
  communicationModule: string;
  pHFactorK: string;
  pHFactorM: string;
  zonesForDevice: string[];
  supplementOne: string;
  supplementTwo: string;
  supplementThree: string;
  supplementFour: string;
  supplementFive: string;
  supplementMixer: string;
  ecSensor: string;
  ecFactorK: string;
  flowMeter: string;
  notificationForWrongFlowMeter: string;
  pressureSensor: string;
  waterLevelSensor: string;
};

const CreateFERTDeviceForm: FC = () => {
  const params = useParams();
  const form = useForm({
    resolver: zodResolver(createFERTDeviceSchema),
    defaultValues: {
      parcelId: params.parcelId as string,
      name: '',
      color: '#4613ad',
      communicationModule: 'LTE',
      pHFactorK: '1',
      pHFactorM: '0',
      zonesForDevice: [],
      supplementOne: 'true',
      supplementTwo: 'true',
      supplementThree: 'true',
      supplementFour: 'false',
      supplementFive: 'false',
      supplementMixer: 'false',
      ecSensor: 'true',
      ecFactorK: '1',
      flowMeter: 'true',
      notificationForWrongFlowMeter: 'true',
      pressureSensor: 'false',
      waterLevelSensor: 'false',
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const availableZones = useAppSelector(getAvailableZones);
  const multiSelectData = availableZones.map((zone) => {
    return {
      label: `Zona ${zone}`,
      value: zone.toString(),
    };
  });

  const handleErrorResponse = (response: CreateDeviceResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    router(-1);
  };

  const onSubmit = async (data: CreateFERTDeviceFormData) => {
    // @ts-ignore
    const response = await dispatch(createFERTDevice(data)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uspešno dodat uredjaj');
    router(-1);
  };

  return (
    <Form
      reset={form.reset}
      formState={form.formState}
      clearErrors={form.clearErrors}
      control={form.control}
      getFieldState={form.getFieldState}
      getValues={form.getValues}
      handleSubmit={form.handleSubmit}
      register={form.register}
      resetField={form.resetField}
      setError={form.setError}
      setFocus={form.setFocus}
      setValue={form.setValue}
      trigger={form.trigger}
      unregister={form.unregister}
      watch={form.watch}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Accordion
          defaultValue="infoForm"
          type="single"
          collapsible
          className="w-full"
        >
          <AccordionItem value="infoForm">
            <AccordionTrigger>Osnovni podaci uredjaja</AccordionTrigger>
            <AccordionContent>
              <FERTDeviceInfoForm
                form={form}
                multiSelectData={multiSelectData}
              />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="factorsForm">
            <AccordionTrigger>Podešavanja koeficijenata</AccordionTrigger>
            <AccordionContent>
              <FERTDeviceFactorForm form={form} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="supplementForm">
            <AccordionTrigger>Prihrana</AccordionTrigger>
            <AccordionContent>
              <FERTDeviceSupplementForm form={form} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="sensorsForm">
            <AccordionTrigger>Senzori</AccordionTrigger>
            <AccordionContent>
              <FERTDeviceSensorsForm form={form} />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <CreateButtonSection
          onGiveUpClick={onGiveUpClick}
          submitText="Dodaj uredjaj"
        />
      </form>
    </Form>
  );
};

export default CreateFERTDeviceForm;
