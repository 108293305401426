import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import EditParcelForm from '@/components/forms/EditParcelForm.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { ParcelWithDevices } from '@/redux/parcels/parcels.types.ts';

type ParcelInfoCardProps = {
  parcelForEdit: ParcelWithDevices;
};

const ParcelInfoCard: FC<ParcelInfoCardProps> = ({ parcelForEdit }) => {
  const router = useNavigate();

  const onDevicesClick = () => {
    router(`/devices/${parcelForEdit.id}`);
  };

  const onUserClick = () => {
    router(`/users/edit/${parcelForEdit.userId}`);
  };

  return (
    <Card x-chunk="edit-parcel-cart">
      <CardHeader className="flex flex-row justify-between items-center">
        <CardTitle>Izmeni parcelu</CardTitle>
        <div className="flex flex-row items-center gap-2">
          <Button
            type="button"
            className="outline outline-[#339c45] text-[#339c45] px-2 py-2"
            onClick={onUserClick}
          >
            Korisnik
          </Button>
          <Button
            type="button"
            className="outline outline-[#2b90d9] text-[#2b90d9] px-2 py-2"
            onClick={onDevicesClick}
          >
            Uredjaji
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <EditParcelForm existingParcel={parcelForEdit} />
      </CardContent>
    </Card>
  );
};

export default ParcelInfoCard;
