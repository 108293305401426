import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormCheckbox from '@/components/controls/CustomFormCheckbox.tsx';
import { Form } from '@/components/ui/form.tsx';
import { TableCell } from '@/components/ui/table.tsx';

type TableCellCheckboxFormProps = {
  form: UseFormReturn<any>;
  field: string;
};

const TableCellCheckboxForm: FC<TableCellCheckboxFormProps> = ({
  form,
  field,
}) => {
  return (
    <TableCell>
      <Form
        reset={form.reset}
        formState={form.formState}
        clearErrors={form.clearErrors}
        control={form.control}
        getFieldState={form.getFieldState}
        getValues={form.getValues}
        handleSubmit={form.handleSubmit}
        register={form.register}
        resetField={form.resetField}
        setError={form.setError}
        setFocus={form.setFocus}
        setValue={form.setValue}
        trigger={form.trigger}
        unregister={form.unregister}
        watch={form.watch}
      >
        <form>
          <CustomFormCheckbox
            form={form}
            name={field}
            checkboxStyle="data-[state=checked]:bg-[#2b90d9]"
          />
        </form>
      </Form>
    </TableCell>
  );
};

export default TableCellCheckboxForm;
