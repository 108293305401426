import { FC, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { routeToPage } from '@/redux/breadcrumbs/breadcrumbs.slice.ts';
import { BreadcrumbLinkType } from '@/redux/breadcrumbs/breadcrumbs.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

type RoutesMiddlewareProps = {
  children: ReactNode;
};

const RoutesMiddleware: FC<RoutesMiddlewareProps> = ({ children }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const generateBreadcrumb = (link: string, linkText: string) => {
    const breadcrumbLink: BreadcrumbLinkType = {
      link,
      linkText,
      showSeparator: false,
    };
    dispatch(routeToPage(breadcrumbLink));
  };

  const routeConfig = {
    '/users': {
      entity: 'Korisnici',
      viewText: 'korisnika',
      addText: 'korisnika',
    },
    '/parcels': { entity: 'Parcele', viewText: 'parcele', addText: 'parcele' },
    '/devices': {
      entity: 'Uredjaji',
      viewText: 'uredjaja',
      addText: 'uredjaja',
    },
    '/distributors': {
      entity: 'Distributeri',
      viewText: 'distributera',
      addText: 'distributera',
    },
    '/fruit-types': { entity: 'Kulture' },
    '/delivery': { entity: 'Isporuka' },
  };

  const handleRoutes = (path: string) => {
    const config = Object.keys(routeConfig).find((route) =>
      path.startsWith(route)
    );

    if (!config) {
      generateBreadcrumb('/', 'Početna');
      return;
    }

    const { entity, viewText, addText } = routeConfig[config];
    if (path.includes('/edit')) {
      generateBreadcrumb(path, `Pregled ${viewText}`);
      return;
    }
    if (path.includes('/create')) {
      generateBreadcrumb(path, `Dodavanje ${addText}`);
      return;
    }

    generateBreadcrumb(path, entity);
  };

  useEffect(() => {
    handleRoutes(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return children;
};

export default RoutesMiddleware;
