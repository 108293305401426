import { MoreHorizontal } from 'lucide-react';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ConfirmDeleteDialog from '@/components/layout/ConfirmDeleteDialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx';
import { TableCell, TableRow } from '@/components/ui/table.tsx';
import { FetchParcel } from '@/redux/parcels/parcels.types.ts';
import { formatSyncDate } from '@/utils/dateUtil.ts';

type ParcelTableRowProps = {
  parcel: FetchParcel;
  onParcelDelete: (parcelId: string) => void;
};

const ParcelTableRow: FC<ParcelTableRowProps> = ({
  parcel,
  onParcelDelete,
}) => {
  const router = useNavigate();
  const [deleteDialogOpened, setIsDeleteDialogOpened] =
    useState<boolean>(false);

  const onEditParcelClick = () => {
    router(`/parcels/edit/${parcel.id}`);
  };

  const onDevicesClick = () => {
    router(`/devices/${parcel.id}`);
  };

  const onDeleteClick = () => {
    setIsDeleteDialogOpened(true);
  };

  const onDeleteParcelClick = async () => {
    onParcelDelete(parcel.id);
  };

  return (
    <>
      <TableRow>
        <TableCell className="max-lg:hidden">{parcel.user}</TableCell>
        <TableCell className="cursor-pointer" onClick={onEditParcelClick}>
          {parcel.name}
        </TableCell>
        <TableCell>{parcel.place}</TableCell>
        <TableCell className="max-md:hidden">{parcel.country}</TableCell>
        <TableCell className="max-md:hidden">
          {formatSyncDate(parcel.lastSync)}
        </TableCell>
        <TableCell>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button aria-haspopup="true" size="icon" variant="ghost">
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={onDevicesClick}>
                Uredjaji
              </DropdownMenuItem>
              <DropdownMenuItem onClick={onEditParcelClick}>
                Izmeni parcelu
              </DropdownMenuItem>
              <DropdownMenuItem
                className="focus:text-red-500 cursor-pointer"
                onClick={onDeleteClick}
              >
                Obriši parcelu
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </TableCell>
      </TableRow>
      <ConfirmDeleteDialog
        headerText="Da li ste sigurni da želite da obrišete parcelu?"
        descriptionText="Ova akcija se ne može poništiti. Trajno ćete obrisati parcelu."
        onContinueClick={onDeleteParcelClick}
        isDialogOpen={deleteDialogOpened}
        setIsDialogOpen={setIsDeleteDialogOpened}
      />
    </>
  );
};

export default ParcelTableRow;
