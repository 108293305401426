import { FC } from 'react';

import CustomTableHeader, {
  TableHeaderType,
} from '@/components/layout/CustomTableHeader.tsx';
import PaginationSection from '@/components/layout/PaginationSection.tsx';
import UserTableRow from '@/components/layout/users/UserTableRow.tsx';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { Table, TableBody } from '@/components/ui/table.tsx';
import { useAppSelector } from '@/redux/hooks.ts';
import {
  getFilteredNumberOfUsers,
  getTotalNumberOfUsers,
  getUsers,
} from '@/redux/users/user.selectors.ts';

const userTableHeaders: TableHeaderType[] = [
  {
    id: 'full_name',
    text: 'Korisnik',
    className: 'max-sm:hidden',
  },
  {
    id: 'place',
    text: 'Mesto',
    className: 'max-sm:hidden',
  },
  {
    id: 'country',
    text: 'Drzava',
    className: 'max-xl:hidden',
  },
  { id: 'email', text: 'Email', className: '' },
  {
    id: 'phoneNumber',
    text: 'Telefon',
    className: 'max-xl:hidden',
    isSortDisabled: true,
  },
  { id: 'parcelCount', text: 'Broj parcela', className: 'max-lg:hidden' },
  { id: 'createdAt', text: 'Datum kreiranja', className: 'max-xl:hidden' },
  { id: 'dateOfPayment', text: 'Datum plaćanja', className: 'max-2xl:hidden' },
  { id: 'paidSubscription', text: 'Platio', className: 'max-lg:hidden' },
  {
    id: 'actions',
    text: '',
    className: '',
    isSortDisabled: true,
  },
];

type UsersTableSectionProps = {
  page: number;
  rowsPerPage: number;
  onColumnClick: (column: string) => void;
  onPageChangeClick: (selectedPage: number) => void;
  onDeleteUser: (userId: string) => void;
  onRowsPerPageChange: (selectedRowsPerPage: number) => void;
};

const UsersTableSection: FC<UsersTableSectionProps> = ({
  page,
  rowsPerPage,
  onColumnClick,
  onPageChangeClick,
  onDeleteUser,
  onRowsPerPageChange,
}) => {
  const users = useAppSelector(getUsers);
  const totalNumberOfUsers = useAppSelector(getTotalNumberOfUsers);
  const filteredNumberOfUsers = useAppSelector(getFilteredNumberOfUsers);

  return (
    <Card x-chunk="dashboard-users">
      <CardHeader>
        <CardTitle>Korisnici</CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <CustomTableHeader
            tableName="users"
            tableHeaders={userTableHeaders}
            onHeaderCellClick={onColumnClick}
          />
          <TableBody>
            {users.map((user, index) => (
              <UserTableRow
                key={`user-table-data-user-${index + 1}`}
                user={user}
                onDeleteUser={onDeleteUser}
              />
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter>
        <PaginationSection
          page={page}
          rowsPerPage={rowsPerPage}
          filteredNumber={filteredNumberOfUsers}
          totalNumber={totalNumberOfUsers}
          onPageChangeClick={onPageChangeClick}
          entity="korisnika"
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </CardFooter>
    </Card>
  );
};

export default UsersTableSection;
