import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DropDownItems } from '@/components/controls/CustomFormDropDown.tsx';
import TableCellCheckboxForm from '@/components/forms/delivery/controls/TableCellCheckboxForm.tsx';
import TableCellDropdownForm from '@/components/forms/delivery/controls/TableCellDropdownForm.tsx';
import { TableCell, TableRow } from '@/components/ui/table.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { patchSENSDelivery } from '@/redux/device/devices.actions.ts';
import {
  DeviceRevision,
  GetSensDeviceResponse,
  PatchSENSRequest,
  SensDevice,
} from '@/redux/device/devices.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import {
  airTempAndHumidTypes,
  batteryTypesSENS,
  flowMeterTypes,
} from '@/utils/device.util.ts';
import { patchSENSSchema } from '@/zod/device.types.zod.ts';

type DeliverySENSDeviceFormProps = {
  device: SensDevice;
  revisionsSENS: DeviceRevision[];
};

export type PatchSENSFormData = {
  id: string;
  rev: string;
  did: string;
  sentekTH30: boolean;
  sentekTHS30: boolean;
  sentekTH60: boolean;
  sentekTHS60: boolean;
  sentekTH90: boolean;
  sentekTHS90: boolean;
  airTempAndHumidType: string;
  dekagonHS1: boolean;
  dekagonHS2: boolean;
  irrigationByTime: boolean;
  irrigationByVolume: boolean;
  batteryType: string;
  flowMeterType: string;
  soilPH: boolean;
  windSpeed: boolean;
  windDirection: boolean;
  amountOfPrecipitation: boolean;
  solarRadiation: boolean;
};

const DeliverySENSDeviceForm: FC<DeliverySENSDeviceFormProps> = ({
  device,
  revisionsSENS,
}) => {
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const subscriptionRef = useRef<ReturnType<typeof watch> | null>();
  const form = useForm({
    resolver: zodResolver(patchSENSSchema),
    defaultValues: {
      id: device.id,
      rev: device.additionalFields.rev,
      did: '',
      sentekTH30: device.additionalFields.sentekTH30,
      sentekTHS30: device.additionalFields.sentekTHS30,
      sentekTH60: device.additionalFields.sentekTH60,
      sentekTHS60: device.additionalFields.sentekTHS60,
      sentekTH90: device.additionalFields.sentekTH90,
      sentekTHS90: device.additionalFields.sentekTHS90,
      dekagonHS1: device.additionalFields.dekagonHS1,
      dekagonHS2: device.additionalFields.dekagonHS2,
      soilPH: device.additionalSensorsValues.soilPH,
      flowMeterType: device.additionalFields.flowMeterType,
      irrigationByTime: device.additionalFields.irrigationByTime,
      irrigationByVolume: device.additionalFields.irrigationByVolume,
      batteryType: device.additionalFields.batteryType,
      airTempAndHumidType: device.additionalFields.airTempAndHumidType,
      windSpeed: device.additionalSensorsValues.windSpeed,
      windDirection: device.additionalSensorsValues.windDirection,
      amountOfPrecipitation:
        device.additionalSensorsValues.amountOfPrecipitation,
      solarRadiation: device.additionalSensorsValues.solarRadiation,
    },
  });
  const { handleSubmit, watch } = form;

  const revisionDropdown: DropDownItems[] = revisionsSENS.map((rev) => {
    return {
      text: rev.revision,
      value: rev.revision,
    };
  });

  const handleErrorResponse = (response: GetSensDeviceResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onSubmit = async (data: PatchSENSFormData) => {
    const dataToSend: PatchSENSRequest = {
      device,
      dataToUpdate: data,
    };

    // @ts-ignore
    const response = await dispatch(patchSENSDelivery(dataToSend)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
    }
  };

  useEffect(() => {
    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
    }

    form.reset({
      id: device.id,
      rev: device.additionalFields.rev,
      did: '',
      sentekTH30: device.additionalFields.sentekTH30,
      sentekTHS30: device.additionalFields.sentekTHS30,
      sentekTH60: device.additionalFields.sentekTH60,
      sentekTHS60: device.additionalFields.sentekTHS60,
      sentekTH90: device.additionalFields.sentekTH90,
      sentekTHS90: device.additionalFields.sentekTHS90,
      dekagonHS1: device.additionalFields.dekagonHS1,
      dekagonHS2: device.additionalFields.dekagonHS2,
      soilPH: device.additionalSensorsValues.soilPH,
      flowMeterType: device.additionalFields.flowMeterType,
      irrigationByTime: device.additionalFields.irrigationByTime,
      irrigationByVolume: device.additionalFields.irrigationByVolume,
      batteryType: device.additionalFields.batteryType,
      airTempAndHumidType: device.additionalFields.airTempAndHumidType,
      windSpeed: device.additionalSensorsValues.windSpeed,
      windDirection: device.additionalSensorsValues.windDirection,
      amountOfPrecipitation:
        device.additionalSensorsValues.amountOfPrecipitation,
      solarRadiation: device.additionalSensorsValues.solarRadiation,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  useEffect(() => {
    // @ts-ignore
    subscriptionRef.current = watch(() => {
      handleSubmit(onSubmit)();
    });

    // Cleanup function to unsubscribe
    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, handleSubmit]);

  return (
    <TableRow>
      <TableCell>{device.name}</TableCell>
      <TableCell>Device DID</TableCell>
      <TableCellDropdownForm
        form={form}
        items={revisionDropdown}
        field="rev"
        isDisabled={false}
      />
      <TableCell>{device.additionalFields.dcRelay}</TableCell>
      <TableCellCheckboxForm form={form} field="sentekTH30" />
      <TableCellCheckboxForm form={form} field="sentekTHS30" />
      <TableCellCheckboxForm form={form} field="sentekTH60" />
      <TableCellCheckboxForm form={form} field="sentekTHS60" />
      <TableCellCheckboxForm form={form} field="sentekTH90" />
      <TableCellCheckboxForm form={form} field="sentekTHS90" />
      <TableCellCheckboxForm form={form} field="dekagonHS1" />
      <TableCellCheckboxForm form={form} field="dekagonHS2" />
      <TableCellCheckboxForm form={form} field="soilPH" />
      <TableCellDropdownForm
        form={form}
        items={flowMeterTypes}
        field="flowMeterType"
        isDisabled={!device.additionalSensorsValues.flowMeter}
      />
      <TableCellCheckboxForm form={form} field="irrigationByTime" />
      <TableCellCheckboxForm form={form} field="irrigationByVolume" />
      <TableCellDropdownForm
        form={form}
        items={batteryTypesSENS}
        field="batteryType"
        isDisabled={false}
      />
      <TableCellDropdownForm
        form={form}
        items={airTempAndHumidTypes}
        field="airTempAndHumidType"
        isDisabled={
          !device.additionalSensorsValues.airTemperature ||
          !device.additionalSensorsValues.airHumidity
        }
      />
      <TableCellCheckboxForm form={form} field="windSpeed" />
      <TableCellCheckboxForm form={form} field="windDirection" />
      <TableCellCheckboxForm form={form} field="amountOfPrecipitation" />
      <TableCellCheckboxForm form={form} field="solarRadiation" />
    </TableRow>
  );
};

export default DeliverySENSDeviceForm;
