import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DropDownItems } from '@/components/controls/CustomFormDropDown.tsx';
import TableCellCheckboxForm from '@/components/forms/delivery/controls/TableCellCheckboxForm.tsx';
import TableCellDropdownForm from '@/components/forms/delivery/controls/TableCellDropdownForm.tsx';
import { TableCell, TableRow } from '@/components/ui/table.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { pathFERTDelivery } from '@/redux/device/devices.actions.ts';
import {
  DeviceRevision,
  FERTDevice,
  GetFERTDeviceResponse,
  PatchFERTRequest,
} from '@/redux/device/devices.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { communicationModules, flowMeterTypes } from '@/utils/device.util.ts';
import { patchFERTSchema } from '@/zod/device.types.zod.ts';

type DeliveryFERTDeviceFormProps = {
  device: FERTDevice;
  revisionsFERT: DeviceRevision[];
};

export type PatchFERTFormData = {
  id: string;
  rev: string;
  did: string;
  mainPumpRelay24: boolean;
  mixerRelay24: boolean;
  communicationModule: string;
  supplementOne: boolean;
  supplementTwo: boolean;
  supplementThree: boolean;
  supplementFour: boolean;
  supplementFive: boolean;
  pHControl: boolean;
  ecControl: boolean;
  pidECControlValve: boolean;
  loudFixTo20mA: boolean;
  flowProportional: boolean;
  fixedPumpPowerMod2: boolean;
  pHWater: boolean;
  flowMeterType: string;
  irrigationByTime: boolean;
  irrigationByVolume: boolean;
  ecSensor: boolean;
  waterLevelSensor: boolean;
};

const DeliveryFERTDeviceForm: FC<DeliveryFERTDeviceFormProps> = ({
  device,
  revisionsFERT,
}) => {
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const subscriptionRef = useRef<ReturnType<typeof watch> | null>();
  const form = useForm({
    resolver: zodResolver(patchFERTSchema),
    defaultValues: {
      id: device.id,
      rev: device.additionalFields.rev,
      did: '',
      mainPumpRelay24: device.additionalFields.mainPumpRelay24,
      mixerRelay24: device.additionalFields.mixerRelay24,
      communicationModule: device.communicationModule,
      supplementOne: device.supplement.supplementOne,
      supplementTwo: device.supplement.supplementTwo,
      supplementThree: device.supplement.supplementThree,
      supplementFour: device.supplement.supplementFour,
      supplementFive: device.supplement.supplementFive,
      pHControl: device.additionalFields.pHControl,
      ecControl: device.additionalFields.ecControl,
      pidECControlValve: device.additionalFields.pidECControlValve,
      loudFixTo20mA: device.additionalFields.loudFixTo20mA,
      flowProportional: device.additionalFields.flowProportional,
      fixedPumpPowerMod2: device.additionalFields.fixedPumpPowerMod2,
      pHWater: device.additionalFields.pHWater,
      flowMeterType: device.additionalFields.flowMeterType,
      irrigationByTime: device.additionalFields.irrigationByTime,
      irrigationByVolume: device.additionalFields.irrigationByVolume,
      ecSensor: device.additionalSensorsValues.ecSensor,
      waterLevelSensor: device.additionalSensorsValues.waterLevelSensor,
    },
  });
  const { handleSubmit, watch } = form;

  const revisionDropdown: DropDownItems[] = revisionsFERT.map((rev) => {
    return {
      text: rev.revision,
      value: rev.revision,
    };
  });

  const handleErrorResponse = (response: GetFERTDeviceResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onSubmit = async (data: PatchFERTFormData) => {
    const dataToSend: PatchFERTRequest = {
      device,
      dataToUpdate: data,
    };

    // @ts-ignore
    const response = await dispatch(pathFERTDelivery(dataToSend)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
    }
  };

  useEffect(() => {
    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
    }

    form.reset({
      id: device.id,
      rev: device.additionalFields.rev,
      did: '',
      mainPumpRelay24: device.additionalFields.mainPumpRelay24,
      mixerRelay24: device.additionalFields.mixerRelay24,
      communicationModule: device.communicationModule,
      supplementOne: device.supplement.supplementOne,
      supplementTwo: device.supplement.supplementTwo,
      supplementThree: device.supplement.supplementThree,
      supplementFour: device.supplement.supplementFour,
      supplementFive: device.supplement.supplementFive,
      pHControl: device.additionalFields.pHControl,
      ecControl: device.additionalFields.ecControl,
      pidECControlValve: device.additionalFields.pidECControlValve,
      loudFixTo20mA: device.additionalFields.loudFixTo20mA,
      flowProportional: device.additionalFields.flowProportional,
      fixedPumpPowerMod2: device.additionalFields.fixedPumpPowerMod2,
      pHWater: device.additionalFields.pHWater,
      flowMeterType: device.additionalFields.flowMeterType,
      irrigationByTime: device.additionalFields.irrigationByTime,
      irrigationByVolume: device.additionalFields.irrigationByVolume,
      ecSensor: device.additionalSensorsValues.ecSensor,
      waterLevelSensor: device.additionalSensorsValues.waterLevelSensor,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  useEffect(() => {
    // @ts-ignore
    subscriptionRef.current = watch(() => {
      handleSubmit(onSubmit)();
    });

    // Cleanup function to unsubscribe
    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, handleSubmit]);

  return (
    <TableRow>
      <TableCell>{device.name}</TableCell>
      <TableCell>Device DID</TableCell>
      <TableCellDropdownForm
        form={form}
        items={revisionDropdown}
        field="rev"
        isDisabled={false}
      />
      <TableCell>{device.additionalFields.acRelay}</TableCell>
      <TableCellCheckboxForm form={form} field="mainPumpRelay24" />
      <TableCellCheckboxForm form={form} field="mixerRelay24" />
      <TableCellCheckboxForm form={form} field="supplementOne" />
      <TableCellCheckboxForm form={form} field="supplementTwo" />
      <TableCellCheckboxForm form={form} field="supplelemtThree" />
      <TableCellCheckboxForm form={form} field="supplementFour" />
      <TableCellCheckboxForm form={form} field="supplementFive" />
      <TableCellCheckboxForm form={form} field="pHControl" />
      <TableCellCheckboxForm form={form} field="ecControl" />
      <TableCellCheckboxForm form={form} field="pidECControlValve" />
      <TableCellCheckboxForm form={form} field="loudFixTo20mA" />
      <TableCellCheckboxForm form={form} field="flowProportional" />
      <TableCellCheckboxForm form={form} field="fixedPumpPowerMod2" />
      <TableCellCheckboxForm form={form} field="pHWater" />
      <TableCellCheckboxForm form={form} field="ecSensor" />
      <TableCellDropdownForm
        form={form}
        items={flowMeterTypes}
        field="flowMeterType"
        isDisabled={!device.additionalSensorsValues.flowMeter}
      />
      <TableCellCheckboxForm form={form} field="irrigationByTime" />
      <TableCellCheckboxForm form={form} field="irrigationByVolume" />
      <TableCellCheckboxForm form={form} field="waterLevelSensor" />
      <TableCellDropdownForm
        form={form}
        items={communicationModules}
        field="communicationModule"
        isDisabled={false}
      />
    </TableRow>
  );
};

export default DeliveryFERTDeviceForm;
