import { FC } from 'react';

import DeliverySENSDeviceForm from '@/components/forms/delivery/DeliverySENSDeviceForm.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table.tsx';
import { DeviceRevision, SensDevice } from '@/redux/device/devices.types.ts';

type SENSDevicesTableProps = {
  userSENSDevices: SensDevice[];
  revisionsSENS: DeviceRevision[];
};

const SENSDevicesTable: FC<SENSDevicesTableProps> = ({
  userSENSDevices,
  revisionsSENS,
}) => {
  return (
    <Card x-chunk="user-fert-devices">
      <CardHeader>
        <CardTitle className="flex items-center justify-between w-full py-3">
          SENS uređaji
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="overflow-x-auto w-full">
          <Table className="min-w-full">
            <TableHeader>
              <TableRow>
                <TableHead className="min-w-[200px]">Device name</TableHead>
                <TableHead className="min-w-[200px]">DID</TableHead>
                <TableHead className="min-w-[200px]">Revision</TableHead>
                <TableHead className="min-w-[200px]">DC Relay 12V</TableHead>
                <TableHead className="min-w-[200px]">
                  SENTEK Temper. + Humidity [30cm]
                </TableHead>
                <TableHead className="min-w-[200px]">
                  SENTEK Temper. + Humidity + Salinity [30cm]
                </TableHead>
                <TableHead className="min-w-[200px]">
                  SENTEK Temper. + Humidity [60cm]
                </TableHead>
                <TableHead className="min-w-[200px]">
                  SENTEK Temper. + Humidity + Salinity [60cm]
                </TableHead>
                <TableHead className="min-w-[200px]">
                  SENTEK Temper. + Humidity [90cm]
                </TableHead>
                <TableHead className="min-w-[200px]">
                  SENTEK Temper. + Humidity + Salinity [90cm]
                </TableHead>
                <TableHead className="min-w-[200px]">
                  Dekagon 10HS Sensor 1
                </TableHead>
                <TableHead className="min-w-[200px]">
                  Dekagon 10HS Sensor 2
                </TableHead>
                <TableHead className="min-w-[200px]">pH Zemljišta</TableHead>
                <TableHead className="min-w-[200px]">Tip protokomera</TableHead>
                <TableHead className="min-w-[200px]">
                  Irrigation by TIME [min]
                </TableHead>
                <TableHead className="min-w-[200px]">
                  Irrigation by Volume [m3]
                </TableHead>
                <TableHead className="min-w-[200px]">Tip baterije</TableHead>
                <TableHead className="min-w-[200px]">
                  Air Temp. + Humidity Type
                </TableHead>
                <TableHead className="min-w-[200px]">
                  Wind Speed Sensor
                </TableHead>
                <TableHead className="min-w-[200px]">
                  Wind Direction Sensor
                </TableHead>
                <TableHead className="min-w-[200px]">
                  Rain fall sensor
                </TableHead>
                <TableHead className="min-w-[200px]">
                  Senzor sunčevog zračenja
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {userSENSDevices?.map((device, index) => (
                <DeliverySENSDeviceForm
                  device={device}
                  revisionsSENS={revisionsSENS}
                  key={`SENS-device-table-row-${index + 1}`}
                />
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

export default SENSDevicesTable;
