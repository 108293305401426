import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DropDownItems } from '@/components/controls/CustomFormDropDown.tsx';
import TableCellCheckboxForm from '@/components/forms/delivery/controls/TableCellCheckboxForm.tsx';
import TableCellDropdownForm from '@/components/forms/delivery/controls/TableCellDropdownForm.tsx';
import { TableCell, TableRow } from '@/components/ui/table.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { pathSTARTDelivery } from '@/redux/device/devices.actions.ts';
import {
  DeviceRevision,
  GetStartDeviceResponse,
  PatchSTARTRequest,
  StartDevice,
} from '@/redux/device/devices.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { flowMeterTypes } from '@/utils/device.util.ts';
import { patchSTARTSchema } from '@/zod/device.types.zod.ts';

type DeliverySTARTDeviceFormProps = {
  device: StartDevice;
  revisionsSTART: DeviceRevision[];
};

export type PatchSTARTFormData = {
  id: string;
  did: string;
  rev: string;
  mainPumpRelay24: boolean;
  mixerRelay24: boolean;
  irrigationByTime: boolean;
  irrigationByVolume: boolean;
  flowMeterType: string;
  humidityOne: boolean;
  humidityTwo: boolean;
};

const DeliverySTARTDeviceForm: FC<DeliverySTARTDeviceFormProps> = ({
  device,
  revisionsSTART,
}) => {
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const subscriptionRef = useRef<ReturnType<typeof watch> | null>();
  const form = useForm({
    resolver: zodResolver(patchSTARTSchema),
    defaultValues: {
      id: device.id,
      rev: device.additionalFields.rev,
      did: '',
      mainPumpRelay24: device.additionalFields.mainPumpRelay24,
      mixerRelay24: device.additionalFields.mixerRelay24,
      flowMeterType: device.additionalFields.flowMeterType,
      irrigationByTime: device.additionalFields.irrigationByTime,
      irrigationByVolume: device.additionalFields.irrigationByVolume,
      humidityOne: device.humidity.humidityOne,
      humidityTwo: device.humidity.humidityTwo,
    },
  });
  const { handleSubmit, watch } = form;

  const revisionDropdown: DropDownItems[] = revisionsSTART.map((rev) => {
    return {
      text: rev.revision,
      value: rev.revision,
    };
  });

  const handleErrorResponse = (response: GetStartDeviceResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onSubmit = async (data: PatchSTARTFormData) => {
    const dataToSend: PatchSTARTRequest = {
      device,
      dataToUpdate: data,
    };

    // @ts-ignore
    const response = await dispatch(pathSTARTDelivery(dataToSend)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
    }
  };

  useEffect(() => {
    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
    }

    form.reset({
      id: device.id,
      rev: device.additionalFields.rev,
      did: '',
      mainPumpRelay24: device.additionalFields.mainPumpRelay24,
      mixerRelay24: device.additionalFields.mixerRelay24,
      flowMeterType: device.additionalFields.flowMeterType,
      irrigationByTime: device.additionalFields.irrigationByTime,
      irrigationByVolume: device.additionalFields.irrigationByVolume,
      humidityOne: device.humidity.humidityOne,
      humidityTwo: device.humidity.humidityTwo,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  useEffect(() => {
    // @ts-ignore
    subscriptionRef.current = watch(() => {
      handleSubmit(onSubmit)();
    });

    // Cleanup function to unsubscribe
    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, handleSubmit]);

  return (
    <TableRow>
      <TableCell>{device.name}</TableCell>
      <TableCell>Device DID</TableCell>
      <TableCellDropdownForm
        form={form}
        items={revisionDropdown}
        field="rev"
        isDisabled={false}
      />
      <TableCell>{device.additionalFields.acRelay}</TableCell>
      <TableCellCheckboxForm form={form} field="mainPumpRelay24" />
      <TableCellCheckboxForm form={form} field="mixerRelay24" />
      <TableCellCheckboxForm form={form} field="humidityOne" />
      <TableCellCheckboxForm form={form} field="humidityTwo" />
      <TableCellDropdownForm
        form={form}
        items={flowMeterTypes}
        field="flowMeterType"
        isDisabled={!device.additionalSensorValues.flowMeter}
      />
      <TableCellCheckboxForm form={form} field="irrigationByTime" />
      <TableCellCheckboxForm form={form} field="irrigationByVolume" />
    </TableRow>
  );
};

export default DeliverySTARTDeviceForm;
