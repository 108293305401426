import { Check, ChevronsUpDown } from 'lucide-react';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import DeliveryPageDevicesTables from '@/components/layout/delivery/DeliveryPageDevicesTables.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command.tsx';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover.tsx';
import { cn } from '@/lib/utils.ts';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import {
  getDevicesForUser,
  getAllDevicesRevisions,
} from '@/redux/device/devices.actions.ts';
import {
  GetDevicesForUserResponse,
  GetDevicesRevisionsResponse,
} from '@/redux/device/devices.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { getDropdownUsers } from '@/redux/users/user.selectors.ts';
import { getUsersForParcelCombobox } from '@/redux/users/users.actions.ts';
import { GetUserDropdownResponse } from '@/redux/users/users.types.ts';

type SelectUserSectionProps = {
  selectedUserId: string;
  onUserSelectChange: (value: string) => void;
};

const SelectUserSection: FC<SelectUserSectionProps> = ({
  selectedUserId,
  onUserSelectChange,
}) => {
  const users = useAppSelector(getDropdownUsers);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className={cn(
            'px-5 py-3 text-base flex justify-between items-center min-w-[200px] font-light',
            !selectedUserId && 'text-muted-foreground'
          )}
        >
          {selectedUserId
            ? users.find((item) => item.id === selectedUserId)?.name
            : 'Odaberite korisika'}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="min-w-[300px] p-0 mr-2">
        <Command>
          <CommandInput placeholder="Pretražite..." />
          <CommandList>
            <CommandEmpty>Nema rezultata pretrage.</CommandEmpty>
            <CommandGroup>
              {users.map((item) => (
                <CommandItem
                  value={item.name}
                  key={item.name}
                  onSelect={() => {
                    onUserSelectChange(item.id);
                  }}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      item.id === selectedUserId ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                  {item.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

const DeliveryPageContent: FC = () => {
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const [selectedUserId, setSelectedUserId] = useState<string>('');

  const handleErrorResponse = (
    response:
      | GetUserDropdownResponse
      | GetDevicesRevisionsResponse
      | GetDevicesForUserResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onUserChange = async (value: string) => {
    setSelectedUserId(value);
    // @ts-ignore
    const response = await dispatch(getDevicesForUser(value)).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
    }
  };

  useEffect(() => {
    const fetchAllDevicesRevisions = async () => {
      // @ts-ignore
      const response = await dispatch(getAllDevicesRevisions()).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    const fetchAllUsers = async () => {
      // @ts-ignore
      const response = await dispatch(getUsersForParcelCombobox()).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchAllUsers();
    fetchAllDevicesRevisions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card x-chunk="dashboard-06-chunk-0" className="overflow-x-auto">
      <CardHeader>
        <CardTitle className="flex items-center justify-between w-full py-3">
          <p className="max-sm:text-start text-center">Uredjaji za korisnika</p>
          <SelectUserSection
            selectedUserId={selectedUserId}
            onUserSelectChange={onUserChange}
          />
        </CardTitle>
      </CardHeader>
      <CardContent>
        {selectedUserId ? <DeliveryPageDevicesTables /> : null}
      </CardContent>
    </Card>
  );
};

export default DeliveryPageContent;
